<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Determine the molar mass of <chemical-latex :content="chemicalCompound1" />:
      </p>

      <calculation-input
        v-model="inputs.molarMass"
        class="mb-5"
        prepend-text="$\text{Molar mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) How many moles of <chemical-latex :content="chemicalCompound1" /> would there be in a
        <number-value :value="mass" unit="\text{g}" /> sample?
      </p>

      <calculation-input
        v-model="inputs.mol"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question382',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molarMass: null,
        mol: null,
      },
    };
  },
  computed: {
    versionNumber1() {
      return this.taskState.getValueBySymbol('versionNumber1').content;
    },
    chemicalCompound1() {
      switch (this.versionNumber1.value) {
        case 1:
          return 'C2H5COOH';
        case 2:
          return 'C6H5COOH';
        case 3:
          return 'C6H5Cl';
        case 4:
          return 'C6H5NH2';
        case 5:
          return 'C6H5OH';
        case 6:
          return 'C6H4Br2';
        default:
          return 'error';
      }
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
